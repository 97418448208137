import React, { Component } from 'react';
import "../../styles/company.scss";
import Photo from "../../components/Photo";
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby";

class Companydetails extends Component {
    static contextType = I18nextContext;
    render() {
        return (
            <div>           
                <div className="banner-feature no-margin">
                    <div className="container container-style">
                        <div className="row">
                            <div className="col-md-12">


                                <div className="overlay-freight"></div>

                                <div className="right-img">

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="contents-imgs">
                                    <div className="pricing_details" lang={I18nextContext._currentValue.language}>
                                        <Link to="/"><Trans>HOME</Trans></Link>/ <span className="view_features"><Trans>COMPANY</Trans></span>
                                    </div>

                                    <div className="sub_main-product-features" lang={I18nextContext._currentValue.language}><Trans>The future of business is right here</Trans></div>
                                    <div className="subsub_main" lang={I18nextContext._currentValue.language}>
                                        <Trans>To simplify life beyond human imagination through emerging and innovative technologies.</Trans>

                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="full_company_profile"></div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="inner_content_container" lang={I18nextContext._currentValue.language}>
                                <div className="row">

                                    <div className="col-md-5 col-lg-6  company_content">
                                        <div className="photo_contents" lang={I18nextContext._currentValue.language}>
                                            <Photo src="image.jpg"  className="homepage img-fluid"/>
                                            <div className="second_photo_contents" lang={I18nextContext._currentValue.language}>
                                                <Photo src="LogoIcon.svg" alt="logipulse-LogoIcon" className="favicon_icon" />
                                            </div>
                                        </div>
                                        <div>
                                            <Photo src="stellosys-logo-design.svg" alt="stellosys-logo" className="logo_company" lang={I18nextContext._currentValue.language} />
                                        </div>

                                        <div className="statements" lang={I18nextContext._currentValue.language}>
                                            <b lang={I18nextContext._currentValue.language}><Trans>Values</Trans></b>
                                            <ul className="listclassname" lang={I18nextContext._currentValue.language}>
                                                <li lang={I18nextContext._currentValue.language}><Trans>Innovation</Trans></li>
                                                <li lang={I18nextContext._currentValue.language}><Trans>Trust</Trans></li>
                                                <li lang={I18nextContext._currentValue.language}><Trans>Transparency</Trans></li>
                                                <li lang={I18nextContext._currentValue.language}><Trans>Quality</Trans></li>
                                                <li lang={I18nextContext._currentValue.language}><Trans>Accountability</Trans></li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div className="col-md-6 offset-md-1 offset-lg-0 company_content" lang={I18nextContext._currentValue.language}><Trans>Established in the year 2010, Stellosys is now a major logistics software development company based in Kochi, India.</Trans>
                                        <div className="first_company_content">
                                            <Trans>The company was born from a need to fill a missing requirement for better and leading-edge solutions in the Logistics and Freight Forwarding industry.</Trans>
                                        </div>
                                        <div className="first_company_content">
                                            <Trans>In the beginning research and applications were arranged based around the needs of the Middle East Logistics hub - Dubai. Stellosys started developing the most demanding cutting-edge solutions for the freight forwarding industry, gradually extending to cover the entire Logistics industry.</Trans>
                                        </div>


                                        <div className="first_company_content">
                                            <Trans>Logipulse – the brainchild of our founder, became a major product in the logistics market in the year 2017 as an on-premises and cloud solution. Logipulse is a next-generation suite of solutions that offers SaaS and Cloud logistics solution that is made highly configurable to gratify customers' precise necessities.</Trans></div>

                                        <div className="first_company_content">
                                            <Trans>Aimed at small to medium businesses, traditional freight forwarders, and related enterprises to transform into a powerful digital platform, Stellosys has a growing team of passionate professionals with backgrounds in the shipping, technology, finance, and logistics industries.</Trans></div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
                <br></br>

                <div className="end_divis_company">
                    <div className="header_contents_sections text-right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <Photo
                                    src="Bottom Image-11.svg"
                                    className="innerimage"
                                    alt="logipulse-footer-images"
                                />
                            </div>

                        </div>
                    </div>
                </div>


            </div>


        );
    }
}

export default withTranslation()(Companydetails);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

