import React from "react"
import Header from "./header"
import Footer from "../components/common/footer"
import Companydet from "../components/common/Companydetails"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  useTranslation,
  I18nextContext,
  Link,
} from "gatsby-plugin-react-i18next"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Aboutus = () => {
  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/about-us/"))
      : JSON.parse(
          JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/about-us/`)
        )

  const canoncalUrl = buildCanonicalUrl()
 
  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();


  const { t } = useTranslation()

  return (
    <div className="Layout">
      <Header />
      <Companydet />
      <Footer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t("About Logipulse by Stellosys - SaaS & Cloud Logistics Solutions")}
        </title>
        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t(
            "Logipulse, the next-gen SaaS and Cloud logistics solution is delivered by Stellosys, a global logistics software development company. Established in 2010, Stellosys has been providing cutting-edge solutions in the Logistics and Freight Forwarding industry."
          )}
        />
        <meta
          name="Keywords"
          content={t(
            "About Logipulse Software, Logipulse Overview, Logipulse about information"
          )}
        />
      </Helmet>
    </div>
  )
}

export default Aboutus
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
